/*------------------------------------*\
  #ELEMENTS
\*------------------------------------*/

html {
  background-color: white;
  
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  
}

body, main {
  display: flex;
	justify-content: center;
	flex-direction: column;
  align-items: center;
}

body {

  font-family: var(--secondary-font);
  color: var(--main-font-color);
  font-size: var(--font-size-small);
}

header {
	display: inherit;
	justify-content: inherit;
}





/*------------------------------------*\
  #HEADINGS
\*------------------------------------*/

h1 {
    font-size: var(--font-size-large);
    font-weight: bolder;
    color: var(--main-color);
    font-family: var(--main-font);
}

h2 {
    font-size: var(--font-size-medium-large);
    font-weight: normal;
    color: var(--tertiary-color);
    font-family: var(--light-font);
}

h3 {
  font-size: var(--font-size-medium-small);
  font-weight: normal;
  color: var(--tertiary-color);
  font-family: var(--broad-font);
}

ul{
  margin: 10px;
}

li{
  margin: 10px;
}

p {
  font-size: var(--font-size-small);
  font-weight: normal;
  color: var(--tertiary-color);
  font-family: var(--secondary-font);
}

p > i {
  font-style: italic;
}

p > f {
  font-size: var(--font-size-small);
  font-weight: normal;
  color: var(--footer-color);
  font-family: var(--secondary-font);
}

a {
  color: var(--footer-font-color);
  text-decoration: none;

}

q > div > p {
  font-size: var(--font-size-medium-small);
  font-weight: normal;
  color: var(--secondary-color);
  font-family: var(--broad-font);
}

div > p {
  padding-bottom: var(--small-margin);
}

q > q {
  margin-top: var(--large-margin);
}





/*------------------------------------*\
  #BUTTONS
\*------------------------------------*/

button {
  background-color: var(--accent-color);
  font-family: var(--broad-font);
  border: 0;
  padding: 15px 15px;
  color: var(--footer-font-color);
  font-size: var(--font-size-medium);
  font-weight: bold;
  transition: .3s;
}



/*------------------------------------*\
  #INPUT-FIELDS
\*------------------------------------*/

input, select, textarea {
  font-family: var(--main-font);
  font-size: var(--font-size-medium);
  border: solid 1px black;
  padding: var(--tiny-margin) var(--tiny-margin);
  margin: var(--small-margin) var(--small-margin);
}

input, textarea {
  width: 275px;
}

textarea {
  min-width: 275px;
  min-height: 50px;
}

select:disabled {
  background-color: var(--disabled-color);
}

@media only screen and (max-width: 1024px) {
  select {
    width: 275px;
  }
}
