.Footer{
    clear: both;
    position: relative;
    margin-top: 0vh;
    height: 15vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Text:link, .Text:visited {
    color: var(--footer-font-color);
    padding: 14px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}

  
.Text:hover, .Text:active {
    background-color: var(--background-secondary-color);
}