/*------------------------------------*\
  #SETTINGS
\*------------------------------------*/

:root {
	/**
     * #COLORS
     */
  --main-font-color: #000000;
  --secondary-font-color: #504c57;
  --footer-font-color: #999999;

  --accent-color:   #5c0627;
  --disabled-color: #e0e0e0;
  --disabled-color-t: #eeeeee;
  --tertiary-color: #414141;
  --footer-color:  rgb(92, 92, 92);
  --background-color:  #f3f3f3;
  --background-secondary-color:  #e9e8e7;
  --box-gradient-top-color: rgb(229, 183, 172);
  --box-gradient-bottom-color:  #8a5167;
  --cup-gradient-top-color: rgb(229, 183, 172);
  --cup-gradient-bottom-color:  #8a5167;
  --deletion-color: tomato;
	--input-error-color: #ffdada;

	/**
     * #FONTS
     */
	--main-font:      'Playfair Display', serif;
  --secondary-font: 'Libre Baskerville', serif;
  --broad-font:     'Viaoda Libre', cursive;
  --light-font:     'Dorsa', sans-serif;
  --barcode-font:   'Libre Barcode 128', cursive;

	--font-size-large:  40px;
  --font-size-medium-large: 38px;
  --font-size-medium-small: 20px;
  --font-size-small:  16px;
  --font-size-smaller:  12px;
  --font-size-tiny:  10px;

	/**
     * #WIDTHS-HEIGHTS-OFFSETS
     */
    --tiny-margin: 5px;
    --small-margin: 10px;
    --medium-margin: 20px;
    --large-margin: 40px;
    --headerMinHeight: 160px;

  /**
    mobile measurements*/
    --mobile-width: 1600px;


}
