
.Header{
    position: relative;
    width: 100vw;
    height: var(--headerMinHeight);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.LinkBar{
    position: relative;
    color: black;
    display: flex;
    flex-direction: row ;
    align-items: center;
    justify-content:space-evenly;
}

#LogoBox{
    display: flex;
    flex-direction: column ;
    align-items: center;
    height: 90px;
}

.HomeButton{
    border-color: black;
    text-decoration: none;
    color: initial;
    margin-bottom: 25px;
}

.LinkButton{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    padding: 5px 32px;
    border-left: 1px;
    border-right: 1px;
    border-color: black;
    text-decoration: none;
    color: initial;
}

.HorisontalSplitter{
    position: absolute;
    height: 1px;
    width: 100vw;
    left:0px;

    margin-top: 6px;

    background-color: black;
    
}